import React from 'react'
import Layout from '../components/layout/layout'
import Footer from '../components/layout/footer'
import { graphql } from 'gatsby'
import { Container, Row, Button } from 'react-bootstrap'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

export default function Larboard({data}) {
  return (
    <Layout>
      <GatsbySeo
        titleTemplate='Larboard | %s'
      />
      <div className="main-container" style={{ backgroundColor: '#0b182a' }}>
        <header className="text-white">
          <div className="d-flex justify-content-center sm-adjust">
            <h1>{data.allFlamelinkLarboardContent.nodes[0].title}</h1>
          </div>
        </header>
        <section className="larboard">
          <Container className="larboard py-5">
            <Row className="justify-content-center">
              <div className="col-lg-5 col-md-6 how-img2">
                <img src={'../../images/pinavo_larboard_stamp.png'} className="img-fluid" alt="pinavo_larboard_stamp" />
              </div>
              <div className="col-lg-5 col-md-6 pt-5 pt-md-0 my-auto how-img" style={{fontSize: '20px', lineHeight: '1.3'}}>
                <h4>discover <b>Larboard</b>,</h4>
                <p>a place where business meets environmental responsibility.</p>
                <Button variant="secondary" style={{color: '#fff'}} disabled>Coming soon</Button>
              </div>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allFlamelinkLarboardContent {
    nodes {
      title
    }
  }
}
`
